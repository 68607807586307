<template>
  <div class="flex md:w-full" ref="containerRef" dir="rtl">
    <SharedRankCard
      class="shadow-below-small rank-card z-1 max-h-36 max-w-36"
      v-for="({ asset, price }, index) in allocations"
      :key="index"
      :loading="!isFetched"
      :rank="asset.rankOrder"
      background-color="gray"
      rank-background="gray"
      variant="small"
      :classes="{
        smallHeader: 'text-left',
      }"
    >
      <div class="z-1 relative flex items-end text-start" dir="ltr">
        <UiInfo
          class="z-1"
          :loading="!isFetched"
          :title="asset.symbol"
          :subtitle="asset.description"
          :skeletons-repeat="2"
          has-image
          :image-size="24"
          :classes="{
            image: 'border bg-white',
            wrapper: 'flex-col items-start gap-1.5',
            title: 'text-xs/4 font-medium',
            subtitle: '!text-xxs/4 line-clamp-1 text-black/50',
            titlesWrapper: 'gap-0',
            skeleton: {
              container: 'gap-1',
              line: 'h-3 first:w-8',
            },
          }"
          :image="asset.logo"
        />

        <UiSkeleton
          :loading="!isFetched"
          :repeat="2"
          :classes="{
            slot: 'flex flex-col items-end text-xs/4 font-medium',
            line: 'h-3 w-8',
            container: 'w-fit gap-1 self-end',
          }"
        >
          <UiPositiveNegative class="whitespace-pre" :value="asset.priceChange" :format="toPercent" />
          <span>{{ toCash(price) }}</span>
        </UiSkeleton>
      </div>
    </SharedRankCard>
  </div>
</template>

<script setup lang="ts">
import { StocksAllocation } from "@finq/stocks/types"

const { isIntersected, containerRef } = useIsIntersected()
const { data, isFetched } = useStocksAllocation(
  { portfolioType: "long10" },
  {
    enabled: isIntersected,
    select: (selectData) =>
      ({
        ...selectData.data,
        data: selectData.data?.reverse(),
      }) as unknown as PaginationResponse<StocksAllocation[]>,
  }
)

const allocations = computed(() => data?.value?.data)
</script>

<style lang="scss" scoped>
.rank-card {
  &:not(:first-child) {
    margin-inline-start: -33px;

    @screen md {
      margin-inline-start: -72px;
    }
  }
}
</style>
